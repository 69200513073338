/**
 * Renders the main content of the application, including the user's profile information and a list of link items.
 *
 * @returns {JSX.Element} The main content of the application.
 */
function MainContent(){
    // Auth user
    // Fetch links for user
    // put links into LinkItem component and Render
    return (
        <div >
            {/* User Info */}
            <ProfileSection username='@username'/>
            {/* Link Content */}
            <div className="space-y-0.9 inline-grid w-2/3 xl:w-[1000px]">
                {
                    linkItems.map(eachLink => {
                        return (<LinkItem 
                            key={eachLink.linkId}
                            title={eachLink.title}
                            url={eachLink.url}
                        />)        
                    })
                }
            </div>
        </div>
    );
}

/**
 * Renders the user's profile section with their username.
 *
 * @param {Object} props - The component's props.
 * @param {string} props.username - The user's username.
 * @returns {JSX.Element} The user's profile section.
 */
function ProfileSection({ username }) {
    // Fetch user profile thumbnail
    // Fetch username
    return (
        <div>
            <p>{ username }</p>
        </div>
    )
}

/**
 * Renders a link item with its title and URL.
 *
 * @param {Object} props - The component's props.
 * @param {string} props.title - The title of the link item.
 * @param {string} props.url - The URL of the link item.
 * @returns {JSX.Element|null} The link item, or null if the title or URL is not provided.
 */

function LinkItem({title, url}) {
    if (title && url) {
        const displayURL = url.toLowerCase().replace('https://', '')
        return (
            <div onClick={() => handleLinkItemClick(url)} className="border-2 border-green-300 rounded-2xl m-2">
                <h2 className="left-px px-3 text-2xl font-bold truncate">{title}</h2>
                <p className="left-px truncate underline">{displayURL}</p>
            </div>
        )
    }
}

/**
 * Opens the clicked link item in a new tab.
 *
 * @param {string} url - The URL of the clicked link item.
 */
function handleLinkItemClick(url) {
    window.open(url, "_blank", "noopener, noreferrer");
}

const linkItems = [
    {
        linkId: 0,
        title: "Github",
        url: "https://github.com/borikanes"
    },
    {
        linkId: 1,
        title: "LinkedIn",
        url: "https://github.com/borikanes"
    },
    {
        linkId: 2,
        title: "Personal Website",
        url: "https://github.com/borikanes"
    },
    {
        linkId: 3,
        title: "Twitter",
        url: "https://github.com/borikanes"
    },
    {
        linkId: 4,
        title: "Bitbucket",
        url: "https://github.com/borikanes"
    },
]

export default MainContent;

/**
 * 
 * MainContent - A React functional component that returns the main content of a webpage. It fetches links for the authenticated user and maps each link to the LinkItem component. It also renders a ProfileSection component to display the authenticated user's profile information.

ProfileSection - A React functional component that renders the authenticated user's profile information. It takes a prop called username and fetches the user's profile thumbnail.

LinkItem - A React functional component that renders a link with a title and URL. If the title and URL are provided, it displays the title and a truncated version of the URL, and registers an onClick event that opens the URL in a new tab. It takes two props called title and url.

handleLinkItemClick - A JavaScript function that takes a URL parameter and opens the URL in a new tab.

linkItems - An array of objects representing links. Each object has a unique linkId, a title, and a URL.
 */