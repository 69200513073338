import MainContent from './MainContent.js';

function App() {
  return (
    <div className="relative h-screen text-center">
      {/* Header */}
      <div className="bg-green-700 h-14 sm:h-20 lg:h-28">
        <Header />
      </div>
      {/* Body */}
      <MainContent />
      {/* Footer */}
      <div className="fixed bottom-10 w-full">
        <Footer />
      </div>
    </div>
  );
}

function Header() {
  return <div className="text-white">Malbo Link</div>
}

function Footer() {
  return <HorizontalList />
}

/**
 * A horizontal list of clickable links with text.
 *
 * @returns {JSX.Element} The JSX element that displays the horizontal list.
 */
function HorizontalList() {
  /*
   * An array of links to display in the horizontal list.
   * @type {{ id: number, text: string, url: string }[]}
  */
  const links = [
    { id: 1, text: "Privacy Policy", url: "https://www.google.com" },
    { id: 2, text: "About", url: "https://www.github.com" },
    { id: 3, text: "Terms and Conditions", url: "https://www.twitter.com" },
  ];

  return (
    <div className="flex justify-center space-x-4">
      {links.map((link) => (
        <a
          key={link.id}
          href={link.url}
          rel="noopener noreferrer"
          className="text-blue-500 hover:text-blue-700"
        >
          {link.text}
        </a>
      ))}
    </div>
  );
}

export default App;

